<template>
  <div>
  <v-app-bar color="white" app clipped-left clipped-right>
    <router-link to="/"><v-img :src="'/images/' + header.logo" contain max-height="36" max-width="106" /></router-link>
    <v-toolbar-title class="headline ml-2" bottom="true">
      <span v-if="header.title" class="primary--text">
        {{ $t('App.title') }}
      </span>
      <span class="secondary--text" v-if="header.site"> | 
        {{ $t('App.site')}}
      </span>
    </v-toolbar-title>
    <v-spacer v-if=$store.state.security_off></v-spacer>
    <div class="error font-weight-bold white--text" dark v-if="$store.state.security_off">
      {{ $t('App.hardcoded-texts.Security has been disabled for remote configuration and modules!') }}  <br />
      {{ $t('App.hardcoded-texts.This should only be done during development.') }}
    </div>
    <v-spacer></v-spacer>
<!--    <div>{{$t("App.wellCome")}} <span v-if="$store.state.user.loggedin">, {{ $store.state.user.name }}</span></div>-->
    <v-spacer></v-spacer>
    <v-progress-circular
      indeterminate
      v-if="!header.title"
      color="primary"
    ></v-progress-circular>
    <v-btn icon to="/" title="Home">
      <v-icon>mdi-home</v-icon>
    </v-btn>
    <v-btn icon title="Help">
      <v-icon>mdi-help</v-icon>
    </v-btn>
    <div>
      <language-switcher />
    </div>
    <template v-if="$store.state.user.loggedin">
      <v-btn  small fab dark @click="logout" title="Logout" :loading="loading" :disabled="loading">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </template>
  </v-app-bar>
    <v-overlay :value="idle_countdown">
      <v-card class="secondary lighten-1">
        <v-card-title class="headline warning white--text" primary-title>{{ $t('App.hardcoded-texts.Idle - Auto Logout') }}</v-card-title>
        <v-card-text class="black--text">{{ $t('App.hardcoded-texts.You have been idle too long and will be logged out in') }} {{ idle_logout }} {{ $t('App.hardcoded-texts.seconds.') }}</v-card-text>
      </v-card>
    </v-overlay>
  </div>
</template>

<script>
import VueCookies from 'vue-cookies'
import LanguageSwitcher from "@/components/layout/language-switcher";

export default {
  name: "the-header",
  props: ["header"],
  data: function() {
    return {
      loading: false,
      idle_countdown: false,
      idle_logout: 30
    }
  },
  components: {
    LanguageSwitcher,
  },
  onIdle() {
      //if ( this.$store.state.user.loggedin ) this.logout(null, true)
      if ( this.$store.state.user.loggedin ) this.idleDialog()
  },
  onActive() {
    this.idle_countdown = false
  },
  methods: {
    idleDialog() {
      this.idle_logout = 30
      this.idle_countdown = true
      let timerId = setInterval( () => {
        if ( !this.isAppIdle ) return clearInterval( timerId )
        this.idle_logout -= 1
        if ( this.idle_logout < 1 ) {
          clearInterval( timerId )
          this.logout(null, true)
        }
      }, 1000 )
    },
    logout(ev, force) {
      if(this.$store.state.idp === 'keycloak') {
        VueCookies.remove('userObj')
        this.$keycloak.logout()
      } else {
        this.loading = true
        fetch("/auth/logout").then(() => {
          this.loading = false
          this.$store.commit('logout')
          this.$emit('loggedout')
          if ( force ) {
            this.$store.commit('setMessage', { type: 'warning', text: this.$t('App.hardcoded-texts.You have been logged out due to inactivity.'), timeout: 3600000 } )
          } else {
            this.$store.commit('setMessage', { type: 'success', text: this.$t('App.hardcoded-texts.You have logged out.') } )
          }
          this.$router.push( {path: "/" } )
        })
      }
    }
  }
}
</script>
